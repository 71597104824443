import VueAxiosInterface from '@asmadsen/vue-axios/types/VueAxiosInterface'
import { vxm } from '@/store'

export default (request, { store }: VueAxiosInterface) => {
  if (vxm.user.hasToken) {
    return {
      ...request,
      headers: {
        Authorization: vxm.user.token,
      },
    }
  }
  return request
}

import router from '@/router'

export function appendSitePrefix(url): string {
  return '/' + router.currentRoute.params.siteId + url
}

export function getCurrentSite(): number {
  return parseInt(router.currentRoute.params.siteId)
}

export function isCurrentSiteId(siteId?: number | null): boolean {
  return siteId === getCurrentSite()
}

import { axios } from '@/plugins/vueaxios'

let styles = null

export const fetchStyles = async () => {
  try {
    const response = await axios.get('/v3/whitelabel', { params: { domain: location.hostname } })
    styles = response.data.data
    return styles
  } catch (e) {
    return null
  }
}

export const getStyles = () => {
  return styles
}

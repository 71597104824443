







import { Component, Vue } from 'vue-property-decorator'
import { getStyles } from '@/utils/styleFetcher'

@Component
export default class Logo extends Vue {
  private get style() {
    return getStyles()
  }
}

import shortid from 'shortid'
import Vue from 'vue'
import { action, Module, mutation, VuexModule } from 'vuex-class-component'
import { Alert } from '@/types/ui/Alert'

const transform = (input: Partial<Alert> | string) => {
  if (typeof input === 'string') {
    return (type) => ({
      id: shortid.generate(),
      type,
      content: '',
      title: input,
      visible: true,
      persistent: false,
    })
  }
  return (type) => ({
    ...{
      id: shortid.generate(),
      type,
      content: '',
      title: '',
      visible: true,
      persistent: false,
    },
    ...input,
  })
}

@Module({ namespacedPath: 'alert/' })
export class AlertStore extends VuexModule {
  alerts: { [propName: string]: Alert } = {}

  @mutation
  add(alert: Alert) {
    Vue.set(this.alerts, alert.id, alert)
  }

  @mutation
  remove(hash: string) {
    Vue.delete(this.alerts, hash)
  }

  @action()
  async toggleVisibility(hash: string) {
    if (this.alerts[hash] !== undefined) {
      this.setVisibility({ hash, visibility: !this.alerts[hash].visible })
    }
  }

  @mutation
  setVisibility({ hash, visibility }: { hash: string; visibility: boolean }) {
    if (this.alerts[hash] !== undefined) {
      this.alerts[hash].visible = visibility
    }
  }

  @action()
  async info(alert: Partial<Alert> | string) {
    this.add(transform(alert)('info'))
  }

  @action()
  async success(alert: Partial<Alert> | string) {
    this.add(transform(alert)('success'))
  }

  @action()
  async warning(alert: Partial<Alert> | string) {
    this.add(transform(alert)('warning'))
  }

  @action()
  async error(alert: Partial<Alert> | string) {
    this.add(transform(alert)('error'))
  }

  @action()
  async onAxiosError(err, prefix: string = null) {
    console.error('onAxiosError: ' + (prefix || err?.prefix), err)
    if (err?.response?.data?.error) {
      await this.error({
        content: err.response.data.error.message,
        title: 'Error: ' + (prefix || err?.prefix || 'Unknown error'),
      })
    } else {
      await this.error({
        content: '' + err,
        title: 'Unknown error',
      })
    }
  }
}

export const alert = AlertStore.ExtractVuexModule(AlertStore)

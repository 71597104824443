/// <reference path="config.d.ts" />

let apiRoot = ''
let legacyLogin = ''
let legacyBaseUrl = ''
const frontendAddress = document.location.href.split('://')[1].split('/')[0]
let bookingPortalUrl = ''
let environment = ''
let guiSocketUrl = ''

if (frontendAddress.match(/localhost|\.local\./)) {
  const devPort = '8086'
  const baseDomain = 'eontyre.local.eonbit.com:' + devPort

  apiRoot = '//' + document.location.host + '/api'
  legacyLogin = document.location.protocol + '//o{siteId}.' + baseDomain + '/v3/auth'
  legacyBaseUrl = document.location.protocol + '//o{siteId}.' + baseDomain
  bookingPortalUrl = document.location.protocol + '//booking.eontyre.local.eonbit.com:8081'
  guiSocketUrl = document.location.protocol + '//socket.eontyre.local.eonbit.com:8661'
  environment = 'development'
} else if (frontendAddress.match(/\.stage\./)) {
  apiRoot = '//' + document.location.host + '/api'
  legacyLogin = document.location.protocol + '//o{siteId}.stage.eontyre.com/v3/auth'
  legacyBaseUrl = document.location.protocol + '//o{siteId}.stage.eontyre.com'
  bookingPortalUrl = document.location.protocol + '//booking.stage.eontyre.com'
  guiSocketUrl = document.location.protocol + '//socket.stage.eontyre.com'
  environment = 'development'
} else {
  apiRoot = 'https://' + document.location.host + '/api/'
  legacyLogin = 'https://o{siteId}.eontyre.com/v3/auth'
  legacyBaseUrl = 'https://o{siteId}.eontyre.com'
  bookingPortalUrl = 'https://booking.eontyre.com'
  guiSocketUrl = document.location.protocol + '//socket.prod.eontyre.com'
  environment = 'production'
}

export const API_ROOT = apiRoot
export const LEGACY_LOGIN = legacyLogin
export const LEGACY_BASE_URL = legacyBaseUrl
export const BOOKING_PORTAL_URL = bookingPortalUrl
export const ENVIRONMENT = environment
export const VERSION = __EONTYRE_VERSION__ // injected by webpack from package.json
export const BUGSNAG_TOKEN = '94a81cfa5f52d48486b62577dc5cb8a1'
export const GUI_SOCKET_URL = guiSocketUrl

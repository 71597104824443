import { VuexModule, Module, mutation, action } from 'vuex-class-component'
import { axios } from '@/plugins/vueaxios'

const state = {
  brands: [],
}

@Module({ namespacedPath: 'vehicles/' })
export class VehiclesStore extends VuexModule {
  brands: any[] = []

  @mutation
  setBrands(brands: any[]) {
    this.brands = brands
  }

  @action
  async fetchBrands() {
    const { data } = await axios.get('/v3/vehicle/brand').then((response) => response.data)

    this.setBrands(data)
  }
}

export const vehicles = VehiclesStore.ExtractVuexModule(VehiclesStore)

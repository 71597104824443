/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
  openDialog: async function openDialog(app, token: string): Promise<void> {
    const updateDialog = this.updateDialog.bind(this)
    const closeDialog = this.closeDialog.bind(this)
    const handleInputDialog = this.handleInputDialog.bind(this)

    const dialogProps = {
      token,
      value: true,
    }
    const eventProps = {
      input: function onInput(val) {
        handleInputDialog(app, val)
      },
      update: function onUpdate() {
        updateDialog()
      },
      close: function onClose() {
        closeDialog(app)
      },
    }
    const loadedComponent = await import('@/components/order/SupplierOrderStatusDialog.vue')
    app.dialogs.push({
      component: loadedComponent.default,
      props: dialogProps,
      events: eventProps,
    })
  },
  handleInputDialog: function handleInputDialog(app, value): void {
    // NOTE: Handle persistent, but for now lets just remove DOM via closeDialog
    if (!value) {
      this.closeDialog(app)
    }
  },
  closeDialog: function closeDialog(app): void {
    // app.dialogs = app.dialogs.filter((dialog) => dialog.component.name !== 'ItemTagDialog')
    // Temp solution
    app.dialogs = []
  },
  updateDialog: function updateDialog(): void {
    // NOTE: Post updated tag list back to legacy frame
    const iframe = document.getElementById('legacy-iframe') as HTMLIFrameElement
    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          type: 'SupplierOrderStatusDialogUpdated',
        },
        '*',
      )
    }
  },
  handle: function handle(app, data): void {
    this.openDialog(app, data.token)
  },
}

import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { user, UserStore } from './modules/user'
import { alert, AlertStore } from './modules/alert'
import { notifications, NotificationsStore } from './modules/notifications'
import { queue, QueueStore } from './modules/queue'
import { overlay, OverlayStore } from './modules/overlay'
import { easypos, EasyposStore } from './modules/easypos'
import { vehicles, VehiclesStore } from './modules/vehicles'
import { booking, BookingStore } from './modules/booking'

Vue.use(Vuex)

const now = new Date()

const state = {
  now,
  loading: false,
}

const mutations = {
  updateNow(state) {
    state.now = new Date()
  },
  setLoading(state, loadingState) {
    state.loading = loadingState
  },
}

export const actions = {
  logout(_store) {
    return new Promise((resolve) => {
      vxm.user.setUserProfile({ firstname: '', lastname: '', id: null })
      vxm.user.clearTokens()
      vxm.user.setSite({})
      vxm.user.setLegacyFrameToken('')
      vxm.queue.onLogout()
      vxm.easypos.clearSettings()
      resolve()
    })
  },
}

export const modules = {
  user,
  alert,
  notifications,
  queue,
  overlay,
  easypos,
  vehicles,
  booking,
}

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'state',
      paths: ['queue.starredStation', 'user.tokens', 'user.settings', 'user.site'],
    }),
    // addSubscriptions({ modules })
  ],
  state,
  actions,
  mutations,
  modules,
})

export default store

export const vxmModules = {
  alert: AlertStore,
  user: UserStore,
  notifications: NotificationsStore,
  overlay: OverlayStore,
  queue: QueueStore,
  vehicles: VehiclesStore,
  easypos: EasyposStore,
  booking: BookingStore,
}

export const vxm = {
  alert: AlertStore.CreateProxy(store, AlertStore),
  user: UserStore.CreateProxy(store, UserStore),
  notifications: NotificationsStore.CreateProxy(store, NotificationsStore),
  overlay: OverlayStore.CreateProxy(store, OverlayStore),
  queue: QueueStore.CreateProxy(store, QueueStore),
  vehicles: VehiclesStore.CreateProxy(store, VehiclesStore),
  easypos: EasyposStore.CreateProxy(store, EasyposStore),
  booking: BookingStore.CreateProxy(store, BookingStore),
}

function watchers(store: Store<any>) {
  store.watch(
    (_state, getters) => getters['user/token'](store),
    async (token) => {
      if (token) {
        await vxm.user.fetchProfile()
        await vxm.notifications.fetchUnreadCounter()
        await vxm.queue.onLogin()
        await vxm.easypos.fetchSettings()
        await vxm.vehicles.fetchBrands()
        await vxm.booking.fetchCalendars()
      }
    },
    { immediate: true },
  )
}

watchers(store)

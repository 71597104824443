import { DateTime, Duration } from 'luxon'
import { formatDateTimeStringAsDate, formatDateTimeStringAsTimeWithoutSeconds } from '@/utils/dateUtils'
import { appendSitePrefix } from '@/utils/routeUtils'

export class BookingWork {
  id: number
  serviceId: number
  placeId: number
  resourceId: number
  isPrimary: boolean
  duration: number
  service: Record<string, unknown>
  place: Record<string, unknown>
  resource: Record<string, unknown>
}

export default class Booking {
  id: number
  calendarId: number
  portalId: number
  time: string // timezone=UTC (from backend)
  duration: number
  ticket: string
  secret: string
  status: string
  type: string
  isDropIn: boolean
  orderId: number
  tyreHotelId: number
  wheelChangeId: number
  v2TyreHotelId: number
  v2WheelChangeId: number
  carId: number
  customerId: number
  customerName: string
  comment: string
  contactName: string
  contactEmail: string
  contactMobile: string
  carLicenseplate: string
  orderMetaData: any
  tyreHotelMetaData: any
  wheelChange: any
  isConfirmed: boolean
  publicUrl: string
  reminderStatus: string
  work: Array<BookingWork>
  carBrand: string

  public constructor(data: Record<string, unknown> = null) {
    this.update(data)
  }

  public update(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.calendarId = data.calendarId ? (data.calendarId as number) : null
    this.portalId = data.portalId ? (data.portalId as number) : null
    this.time = data.time ? (data.time as string) : ''
    this.duration = data.duration ? (data.duration as number) : null
    this.ticket = data.ticket ? (data.ticket as string) : ''
    this.secret = data.secret ? (data.secret as string) : ''
    this.status = data.status ? (data.status as string) : ''
    this.type = data.type ? (data.type as string) : ''
    this.isDropIn = !!data.isDropIn
    this.orderId = data.orderId ? (data.orderId as number) : null
    this.tyreHotelId = data.tyreHotelId ? (data.tyreHotelId as number) : null
    this.wheelChangeId = data.wheelChangeId ? (data.wheelChangeId as number) : null
    this.v2TyreHotelId = data.v2TyreHotelId ? (data.v2TyreHotelId as number) : null
    this.v2WheelChangeId = data.v2WheelChangeId ? (data.v2WheelChangeId as number) : null
    this.carId = data.carId ? (data.carId as number) : null
    this.customerId = data.customerId ? (data.customerId as number) : null
    this.comment = data.comment ? (data.comment as string) : ''
    this.customerName = data.customerName ? (data.customerName as string) : ''
    this.contactName = data.contactName ? (data.contactName as string) : ''
    this.contactEmail = data.contactEmail ? (data.contactEmail as string) : ''
    this.contactMobile = data.contactMobile ? (data.contactMobile as string) : ''
    this.carLicenseplate = data.carLicenseplate ? (data.carLicenseplate as string) : ''
    this.orderMetaData = data.orderMetaData
    this.tyreHotelMetaData = data.tyreHotelMetaData
    this.wheelChange = data.wheelChange
    this.isConfirmed = !!data.isConfirmed
    this.publicUrl = data.publicUrl ? (data.publicUrl as string) : null
    this.reminderStatus = data.reminderStatus ? (data.reminderStatus as string) : ''
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.carBrand = data.car?.brand?.name ? data.car.brand.name : ''
    if (data.work) {
      this.work = data.work as Array<BookingWork>
    }
  }

  public clone(): Booking {
    return new Booking(JSON.parse(JSON.stringify(this)))
  }

  public get displayTime(): string {
    if (!this.time) {
      return ''
    }
    let endDateTime = ''
    if (this.duration) {
      endDateTime = DateTime.fromISO(this.time)
        .plus(Duration.fromISO('PT' + this.duration + 'M'))
        .toFormat('yyyy-MM-dd HH:mm:ss')
        .replace(' ', 'T')
    } else {
      endDateTime = DateTime.fromISO(this.time)
        .toFormat('yyyy-MM-dd HH:mm:ss')
        .replace(' ', 'T')
    }
    return this.isDropIn
      ? 'Drop-in'
      : formatDateTimeStringAsTimeWithoutSeconds(this.time) +
          ' - ' +
          formatDateTimeStringAsTimeWithoutSeconds(endDateTime)
  }

  public get displayStartTime(): string {
    return this.isDropIn ? 'Drop-in' : formatDateTimeStringAsTimeWithoutSeconds(this.time)
  }

  public get displayDate(): string {
    return formatDateTimeStringAsDate(this.time)
  }

  public get orderUrl(): string {
    if (!this.orderId) {
      return ''
    }
    return appendSitePrefix('/' + this.orderId + '/order2/main')
  }

  public get tyreHotelUrl(): string {
    if (!this.tyreHotelId) {
      return ''
    }
    if (this.v2TyreHotelId) {
      // tuba: todo - is field populated? todo2 - fix link
      return appendSitePrefix('/tyre-hotel/details/' + this.tyreHotelId)
    } else {
      return appendSitePrefix('/' + this.tyreHotelId + '/storage/item')
    }
  }

  public get customerUrl(): string {
    if (!this.customerId) {
      return ''
    }
    return appendSitePrefix('/' + this.customerId + '/customer/show')
  }

  public get carUrl(): string {
    if (!this.carId) {
      return ''
    }
    return appendSitePrefix('/' + this.customerId + '/customer/show?car=' + this.carId)
  }

  private get typeStatusInfo() {
    if (this.status === 'Cancelled') {
      return {
        icon: 'far fa-times-circle',
        color: 'red',
        text: 'Booking is cancelled',
      }
    }
    switch (this.type) {
      case 'Auto':
        if (this.isConfirmed) {
          return {
            icon: 'fa fa-thumbs-up',
            color: 'green',
            text: 'Confirmed auto-booking',
          }
        } else {
          return {
            icon: 'fa fa-pause-circle',
            color: 'blue',
            text: 'Unconfirmed auto-booking',
          }
        }
      case 'Admin':
        return {
          icon: 'far fa-user-crown',
          color: 'green',
          text: 'Booked by admin',
        }
      case 'Portal':
        return {
          icon: 'fa fa-user',
          color: 'green',
          text: 'Booked by customer',
        }
    }
    return {
      icon: '',
      color: '',
      text: '',
    }
  }

  private get statusColor(): string {
    switch (this.status) {
      case 'Active':
        return this.isConfirmed ? 'green' : 'blue'
      case 'Completed':
        return 'green'
      case 'Cancelled':
        return 'red'
    }
  }

  private get statusIcon(): string {
    const info = this.typeStatusInfo
    return info?.icon || ''
  }

  private get statusText(): string {
    const info = this.typeStatusInfo
    return info?.text || ''
  }

  private get primaryWork(): BookingWork {
    if (this.work) {
      for (let i = 0; i < this.work.length; i++) {
        if (this.work[i].isPrimary) {
          return this.work[i]
        }
      }
    }
    return null
  }

  public get addonServices(): Array<Record<string, unknown>> {
    const result = []
    if (this.work) {
      for (let i = 0; i < this.work.length; i++) {
        if (!this.work[i].isPrimary) {
          result.push(this.work[i].service)
        }
      }
    }
    return result
  }

  public get addonServiceIds(): Array<number> {
    const services = this.addonServices
    const result = []
    for (let i = 0; i < services.length; i++) {
      result.push(services[i].id)
    }
    return result
  }

  public get primaryService() {
    return this.primaryWork?.service
  }

  public get primaryServiceName(): string {
    const work = this.primaryWork
    return (work?.service?.name as string) || ''
  }

  public get primaryResourceName(): string {
    const work = this.primaryWork
    return (work?.resource?.name as string) || ''
  }

  public get primaryPlaceName(): string {
    const work = this.primaryWork
    return (work?.place?.name as string) || ''
  }

  public get primaryPlaceId(): number {
    const work = this.primaryWork
    return (work?.place?.id as number) || null
  }

  public get isNew(): boolean {
    return !this.id
  }
}

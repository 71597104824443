
  import Vue from 'vue'
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import URLParse from 'url-parse'
  import { Debounce } from 'lodash-decorators'

  const STATE = {
    LOADING: 'LOADING',
    RESOLVED: 'RESOLVED',
    REJECTED: 'REJECTED',
  }

  type Pagination = {
    descending: boolean
    page: number
    itemsPerPage: number
    sortBy: unknown[]
    totalItems: number
  }

  @Component
  export default class FetchData extends Vue {
    @Prop({ default: false, type: Boolean }) authenticate
    @Prop({ required: true, type: String }) endpoint
    @Prop({ default: 'get', type: String }) method
    @Prop({
      default: () => {},
      type: Object,
    })
    pagination

    @Prop({ default: (v) => v, type: Function }) postTransform
    @Prop() payload

    defaultPagination: Pagination = {
      descending: false,
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      totalItems: 0,
    }

    data = []
    meta = null
    state = STATE.LOADING

    get url() {
      const url = URLParse(this.endpoint, null, true)
      url.query.sort_by = `${this.computedPagination.sortBy}:${this.computedPagination.descending ? 'desc' : 'asc'}`
      url.query.page = this.computedPagination.page
      url.query.per_page = this.computedPagination.itemsPerPage
      return url.toString() // `${url}?${queryString.stringify(query)}`
    }

    get computedPagination() {
      return this.hasPagination ? this.pagination : this.defaultPagination
    }

    get hasPagination() {
      const pagination = this.pagination || {}

      return Object.keys(pagination).length > 0
    }

    get hasResolvedSlot() {
      return !!this.resolvedSlot
    }

    get resolvedSlot() {
      return this.$scopedSlots.resolved || this.$scopedSlots.default || this.$slots.resolved || this.$slots.default
    }

    get hasRejectedSlot() {
      return !!this.rejectedSlot
    }

    get rejectedSlot() {
      return this.$scopedSlots.rejected || this.$slots.rejected
    }

    get hasLoadingSlot() {
      return !!this.loadingSlot
    }

    get loadingSlot() {
      return this.$scopedSlots.loading || this.$slots.loading
    }

    get isLoading() {
      return this.state === STATE.LOADING
    }

    get isRejected() {
      return this.state === STATE.REJECTED
    }

    get isResolved() {
      return this.state === STATE.RESOLVED
    }

    mounted() {
      this.fetchData()
    }

    @Watch('url')
    @Debounce(200)
    fetchData() {
      this.state = STATE.LOADING
      /*
			setTimeout(() => {
				this.state = STATE.REJECTED
				setTimeout(() => {
					this.state = STATE.RESOLVED
				}, 2000)
			}, 2000)
			*/
      /*
       */
      this.$axios
        .request({
          method: this.method,
          url: this.url,
          data: this.payload,
        })
        .then((response) => {
          try {
            this.data = response.data.data.map(this.postTransform)
          } catch (e) {
            console.error(e)
          }
          this.meta = response.data.meta
          this.updatePagination({
            page: this.meta.current_page,
            itemsPerPage: this.meta.per_page,
            totalItems: this.meta.total_items,
          })
          this.state = STATE.RESOLVED
        })
        .catch((error) => {
          this.state = STATE.REJECTED
        })
    }

    @Watch('state')
    onStateChanged() {
      let event
      switch (this.state) {
        case STATE.LOADING:
          event = 'loading'
          break
        case STATE.REJECTED:
          event = 'failed'
          break
        case STATE.RESOLVED:
          event = 'success'
          break
      }
      if (event) {
        this.$emit(event)
      }
    }

    updatePagination(val) {
      const pagination = this.hasPagination ? this.pagination : this.defaultPagination

      const updatedPagination = { ...pagination, ...val }
      this.$emit('update:pagination', updatedPagination)

      if (!this.hasPagination) {
        this.defaultPagination = updatedPagination
      }
    }

    renderSlot(slot, data = {}) {
      if (typeof slot === 'function') {
        return slot(data)
      }
      return slot[0]
    }

    render(h) {
      const props = {
        data: this.data,
        meta: this.meta,
        loading: this.isLoading,
        failed: this.isRejected,
        success: this.isLoading,
        events: {
          'update:pagination': this.updatePagination,
        },
        props: {
          loading: this.isLoading,
          pagination: this.computedPagination,
          items: this.data,
          totalItems: this.computedPagination.totalItems,
        },
      }
      if (this.state === STATE.LOADING && this.hasLoadingSlot) {
        return this.renderSlot(this.loadingSlot, props)
      }
      if (this.state === STATE.REJECTED && this.hasRejectedSlot) {
        return this.renderSlot(this.rejectedSlot, props)
      }
      if (this.hasResolvedSlot) {
        return this.renderSlot(this.resolvedSlot, props)
      }
      return h('span', {}, 'Component has no slots')
    }
  }

import LegacyFrameLoad from './strategies/LegacyFrameLoad'
import LegacyFrameShowBookingDialog from './strategies/BookingDialog'
import LegacyFrameShowTagsDialog from './strategies/TagsDialog'
import LegacyFrameShowSupplierOrderStatusDialog from './strategies/SupplierOrderStatusDialog'

// Target - ./App.vue
export default {
  LegacyFrameLoad,
  LegacyFrameShowBookingDialog,
  LegacyFrameShowTagsDialog,
  LegacyFrameShowSupplierOrderStatusDialog,
}

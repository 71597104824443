
























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class EonButton extends Vue {
  @Prop({
    default: (): EonButtonModel => {
      return {
        // Make sure correct default values are set
        // this.shortcut = this.action.shortcut || '' // will not allow passing this in, since cannot actually control the shortcut yet, see mounted()
        id: '',
        label: '',
        route: null,
        click: null,
        requiresConfirmation: null,
        confirmationTitle: null,
        confirmationText: null,
        tooltip: '',
        type: '',
        class: '',
        color: '',
        disabled: false,
        icon: '',
        dense: false,
        download: false,
        target: null,
        template: null,
      }
    },
  })
  private action: EonButtonModel

  private isLoading = false
  private isDownloading = false

  created() {
    this.updateAction()
  }

  beforeUpdate() {
    this.updateAction()
  }

  private updateAction() {
    this.action.id = this.$attrs.id || this.action.id
    this.action.label = this.$attrs.label || this.action.label
    this.action.route = this.$attrs.route || this.action.route
    this.action.click = this.$attrs.click || this.action.click
    this.action.requiresConfirmation = this.$attrs.requiresConfirmation || this.action.requiresConfirmation
    this.action.confirmationTitle = this.$attrs.confirmationTitle || this.action.confirmationTitle
    this.action.confirmationText = this.$attrs.confirmationText || this.action.confirmationText
    this.action.tooltip = this.$attrs.tooltip || this.action.tooltip
    this.action.type = this.$attrs.type || this.action.type
    this.action.class = this.$attrs.class || this.action.class
    this.action.color = this.$attrs.color || this.action.color
    this.action.disabled = this.$attrs.disabled || this.action.disabled
    this.action.icon = this.$attrs.icon || this.action.icon
    this.action.dense = this.$attrs.dense || this.action.dense
    this.action.download = this.$attrs.download || this.action.download
    this.action.target = this.$attrs.target || this.action.target
    this.action.template = this.$attrs.template || this.action.template

    switch (this.action.template) {
      case 'edit':
      case 'new':
        if (!this.action.label && !this.$slots?.default) {
          this.action.label = this.action.template === 'new' ? 'c:common:Save' : 'c:common:Update'
        }
        if (!this.action.icon) {
          this.action.icon = this.action.template === 'new' ? 'fa fa-plus' : 'fa fa-check'
        }
        this.action.color = 'green'
        this.action.class = 'white--text text-capitalize'
        break
    }
  }

  private isText(): boolean {
    return this.action.type === 'link'
  }

  private async clickAction() {
    if (this.isLoading) {
      return
    }

    if (this.action.requiresConfirmation === true) {
      this.$emit('confirmationDialog', { action: this.action })
    } else {
      this.isLoading = true
      await this.action.click()
      this.isLoading = false
    }
  }

  private downloadHandler() {
    this.isDownloading = true
    const fileUrl = this.action.route()
    const link = document.createElement('a')
    link.addEventListener('click', () => {
      setTimeout(() => {
        document.body.removeChild(link)
        this.isDownloading = false
      }, 6000) // NOTE: This is just a hack to make sure the user don't keep clicking the button while backend still generating CSV
    })
    link.href = fileUrl
    document.body.appendChild(link)
    link.click()
  }
}

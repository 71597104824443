





























































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import Queue from '@/store/modules/queue/Queue'
import Person from '@/store/modules/queue/Person'
import Status from '@/store/modules/queue/Status'
import CustomerQueuePerson from '@/components/customer-queue/CustomerQueuePerson.vue'
import { DateTime } from 'luxon'

@Component({
  components: {
    CustomerQueuePerson,
  },
})
export default class CustomerQueueControl extends Vue {
  private get connected(): boolean {
    return vxm.queue.connected
  }

  private get myQueueId(): string {
    return vxm.queue.myQueueId
  }

  private set myQueueId(myQueueId: string) {
    vxm.queue.setMyQueueId(myQueueId)
  }

  private get now(): DateTime {
    return vxm.queue.now
  }

  private get enabledQueues(): Array<Queue> {
    const result = []
    for (let i = 0; i < vxm.queue.queues.length; i++) {
      if (i === 0 || vxm.queue.options.enableGarageQueue) {
        result[result.length] = vxm.queue.queues[i]
      }
    }
    return result
  }

  private getPeopleInQueueWithStatusExpediting(queueId: string): Array<Person> {
    return this.findPeopleInQueueWithStatus(queueId, Status.Expedite)
  }

  private getPeopleInQueueWithStatusWaiting(queueId: string): Array<Person> {
    return this.findPeopleInQueueWithStatus(queueId, Status.Wait)
  }

  private hasPeopleInQueueWithStatusExpediting(queueId: string): boolean {
    return this.getPeopleInQueueWithStatusExpediting(queueId).length > 0
  }

  private hasPeopleInQueueWithStatusWaiting(queueId: string): boolean {
    return this.getPeopleInQueueWithStatusWaiting(queueId).length > 0
  }

  private get peopleCompleted(): Array<Person> {
    return vxm.queue.peopleCompleted
  }

  private get hasPeopleCompleted(): boolean {
    return vxm.queue.hasPeopleCompleted
  }

  private countPeopleInQueueWithDoneStatus(queueId: string, isDone: boolean): number {
    let count = 0
    for (let i = 0; i < vxm.queue.people.length; i++) {
      const p = vxm.queue.people[i]
      if (p.queueId === queueId && (p.status === Status.Done) === isDone) {
        count++
      }
    }
    return count
  }

  private findPeopleInQueue(queueId: string): Array<Person> {
    const result = []
    vxm.queue.people.forEach((p) => {
      if (p.queueId === queueId) {
        result.push(p)
      }
    })
    return result
  }

  private findPeopleInQueueWithStatus(queueId: string, status: number): Array<Person> {
    const result = []
    const people = this.findPeopleInQueue(queueId)
    people.forEach((p) => {
      if (p.status === status) {
        result.push(p)
      }
    })
    if (status === Status.Expedite) {
      result.sort((a: Person, b: Person) => {
        const aIsExpediting = this.isExpeditingPerson(a)
        const bIsExpediting = this.isExpeditingPerson(b)
        if (aIsExpediting === bIsExpediting) {
          const aWaitTime = a.queue?.waitTimeMs || 0
          const bWaitTime = b.queue?.waitTimeMs || 0
          return aWaitTime > bWaitTime ? 1 : -1
        } else {
          return aIsExpediting ? -1 : 1
        }
      })
    } else {
      result.sort((a: Person, b: Person) => {
        const aWaitTime = a.queue?.waitTimeMs || 0
        const bWaitTime = b.queue?.waitTimeMs || 0
        return aWaitTime > bWaitTime ? 1 : -1
      })
    }
    return result
  }

  private get myQueue(): Queue {
    if (this.myQueueId) {
      const queues = vxm.queue.queues
      for (let i = 0; i < queues.length; i++) {
        if (queues[i].id === this.myQueueId) {
          return queues[i]
        }
      }
    }
    return null
  }

  private get myTargets(): Array<string> {
    return this.myQueueId === 'garage' ? vxm.queue.options.garageTargets : vxm.queue.options.expediteTargets
  }

  private set myTarget(value: string) {
    if (!this.myQueue) {
      return
    }
    this.myQueue.myTarget = value
  }

  private get myTarget(): string {
    return this.myQueue?.myTarget || ''
  }

  private getMyTargetForQueue(queueId: string): string {
    for (let i = 0; i < vxm.queue.queues.length; i++) {
      if (vxm.queue.queues[i].id === queueId) {
        return vxm.queue.queues[i].myTarget
      }
    }
    return ''
  }

  public isExpeditingPerson(person: Person): boolean {
    return person.id === vxm.queue.expeditingPersonId
  }

  private expediteNext(): void {
    const queue = this.myQueue
    if (!queue) {
      alert('You have not selected your queue')
      return
    }
    const waiting = this.getPeopleInQueueWithStatusWaiting(queue.id)
    if (!waiting || waiting.length === 0) {
      alert('Nobody is waiting in ' + queue.name + ' queue')
      return
    }
    const person = waiting[0]
    if (person.disableExpedite) {
      alert(this.$t('c:customer-queue:Can only expedite when waiting'))
      return
    }
    this.expeditePerson(person)
  }

  private expeditePerson(person: Person) {
    const target = this.getMyTargetForQueue(person.queueId)
    person.loading = true
    this.$axios
      .post('/v3/queue/' + person.id + '/expedite', { target })
      .then((_response) => {
        person.loading = false
        person._expanded = true
        vxm.queue.setExpeditePersonId(person.id)
      })
      .catch((err) => {
        person.loading = false
        vxm.alert.onAxiosError(err, 'Failed to expedite person')
      })
  }

  private movePersonToQueue(person: Person, queueId: string) {
    person.loading = true
    this.$axios
      .post('/v3/queue/wait/' + person.id + '/' + queueId)
      .then((_response) => {
        person.loading = false
        person._expanded = false
      })
      .catch((err) => {
        person.loading = false
        vxm.alert.onAxiosError(err, 'Failed to move person to queue: ' + queueId)
      })
  }

  private movePersonToExpedite(person: Person) {
    this.movePersonToQueue(person, 'expedite')
  }

  private movePersonToGarage(person: Person) {
    this.movePersonToQueue(person, 'garage')
  }

  private movePersonToDone(person: Person) {
    person.loading = true
    this.$axios
      .post('/v3/queue/' + person.id + '/done')
      .then((_response) => {
        person.loading = false
        person._expanded = false
      })
      .catch((err) => {
        person.loading = false
        vxm.alert.onAxiosError(err, 'Failed to move person to done')
      })
  }

  private processPerson(person: Person) {
    if (person.queue?.status === Status.Wait) {
      this.expeditePerson(person)
    } else if (person.queue?.status === Status.Expedite) {
      if (person.queueId === 'expedite' && vxm.queue.options.enableGarageQueue) {
        this.movePersonToGarage(person)
      } else {
        this.movePersonToDone(person)
      }
    }
  }

  private recallPerson(person: Person) {
    if (person.disableRecall) {
      alert(this.$t('c:customer-queue:Can only recall when expediting'))
      return
    }
    person.loading = true
    const target = this.getMyTargetForQueue(person.queueId)
    this.$axios
      .post('/v3/queue/' + person.id + '/call', { target })
      .then((_response) => {
        person.loading = false
      })
      .catch((err) => {
        person.loading = false
        vxm.alert.onAxiosError(err, 'Failed to recall person')
      })
  }
}

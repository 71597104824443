export default class Queue {
  public id: string
  public name: string
  public myTarget: string

  public constructor(data: Queue) {
    this.id = data.id
    this.name = data.name
    this.myTarget = data.myTarget
  }
}

import VueI18n from 'i18nsrv-vue'
import Vue from 'vue'

Vue.use(VueI18n)

const i18n = new VueI18n({
  server: '/api/i18n',
  markMissing: true,
  reportMissing: true,
  reportUsage: true,
  dataset: 'frontend',
})

export default i18n

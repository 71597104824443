import { VuexModule, Module, mutation } from 'vuex-class-component'

@Module({ namespacedPath: 'overlay/' })
export class OverlayStore extends VuexModule {
  section = ''

  @mutation
  open(section: string) {
    this.section = section
  }

  @mutation
  close() {
    this.section = ''
  }
}

export const overlay = OverlayStore.ExtractVuexModule(OverlayStore)

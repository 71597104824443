







import Vue from 'vue'
import Component from 'vue-class-component'
import NotificationsSection from './navbar/NotificationsSection.vue'
import { vxm } from '@/store'

@Component({
  components: {
    NotificationsSection,
  },
})
export default class Overlay extends Vue {
  private get visible() {
    return false // this.$store.state.overlay.section != ''
  }

  private hide() {
    vxm.overlay.close()
  }
}





















import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import BaseAlert from '@/components/BaseAlert.vue'

@Component({
  components: { BaseAlert },
})
export default class AlertContainer extends Vue {
  get alerts() {
    return vxm.alert.alerts
  }

  closeAlert(identifier: string) {
    vxm.alert.setVisibility({ hash: identifier, visibility: false })
    setTimeout(() => {
      vxm.alert.remove(identifier)
    }, 500)
  }
}

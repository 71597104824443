import PersonQueueStatus from '@/store/modules/queue/PersonQueueStatus.ts'
import Status from '@/store/modules/queue/Status.ts'
import { UpdateMessage } from '@/store/modules/queue/Socket'
import { vxm } from '@/store'

export default class Person {
  public id: number
  public _licenseplate: string
  public name: string
  public queueId: string
  public loading: boolean
  public _expanded: boolean
  public customerId: number
  public carId: number
  public _carBrand: string
  public tyreHotelId: number
  public tyreHotelV2: boolean
  public orderId: number
  public bookedAt: string
  private readonly queues = {
    expedite: new PersonQueueStatus(null),
    garage: new PersonQueueStatus(null),
  }

  public constructor(data: UpdateMessage) {
    if (!data.queues) {
      data.queues = {
        expedite: null,
        garage: null,
      }
    }
    this.id = data.id
    this._licenseplate = data.licenseplate
    this.name = data.name
    this.queueId = data.queue
    this.loading = false
    this._expanded = false
    this.customerId = data.customer_id
    this.carId = data.car_id
    this._carBrand = data.car_brand
    this.tyreHotelId = data.tyre_hotel_id
    this.tyreHotelV2 = data.tyre_hotel_v2
    this.orderId = data.order_id
    this.bookedAt = data.booking?.start_time ? data.booking.start_time : ''
    this.queues = {
      expedite: new PersonQueueStatus(data.queues.expedite),
      garage: new PersonQueueStatus(data.queues.garage),
    }
  }

  public update(data: UpdateMessage): void {
    this.carId = data.car_id
    this._carBrand = data.car_brand
    this.queueId = data.queue
    this.queues.expedite.update(data.queues.expedite)
    this.queues.garage.update(data.queues.garage)
  }

  public get licenseplate(): string {
    return this._licenseplate || '-'
  }

  public get carBrand(): string {
    return this._carBrand || '-'
  }

  public get waitTimes(): Array<{ queue: string; time: string }> {
    const result = []
    for (const id of ['expedite', 'garage']) {
      if (!this.queues[id]) {
        continue
      }
      const wait = this.queues[id].waitTimeFormatted
      if (wait !== '') {
        result[result.length] = {
          queue: id,
          time: this.queues[id].waitTimeFormatted,
        }
      }
    }
    return result
  }

  public get queue(): PersonQueueStatus {
    return this.queues[this.queueId]
  }

  public get target(): string {
    return this.queue?.target || ''
  }

  public get status(): number {
    return this.queue?.status || null
  }

  public get disableExpedite(): boolean {
    return this.status !== Status.Wait
  }

  public get disableRecall(): boolean {
    return this.status !== Status.Expedite
  }

  public get disableDone(): boolean {
    return this.status === Status.Done
  }

  public get disableProcess(): boolean {
    return this.status === Status.Done
  }

  public get hasExpediteStatus(): boolean {
    return this.status === Status.Expedite
  }

  // Actions

  public expand(): void {
    this._expanded = !this._expanded
  }

  public get expanded(): boolean {
    const isExpeditingMe = this.id === vxm.queue.expeditingPersonId
    return isExpeditingMe || this._expanded
  }
}









import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component
export default class PaymentWarning extends Vue {
  get days() {
    return vxm.user.paymentExpiration
  }
}

export default class Storage {
  private static namespace = 'eontyre.customer-queue.'

  public static set(key: string, value: string): void {
    localStorage.setItem(Storage.namespace + key, '' + value)
  }

  public static get(key: string): string {
    return localStorage.getItem(Storage.namespace + key)
  }
}

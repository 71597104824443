import { action, Module, mutation, VuexModule } from 'vuex-class-component'
import { axios } from '@/plugins/vueaxios'

export class Calendar {
  id: number
  name: string
  isDefault: boolean
}

@Module({ namespacedPath: 'booking/' })
export class BookingStore extends VuexModule {
  calendars: Array<Calendar> = []

  @mutation
  setCalendars(calendars: Array<Calendar>) {
    this.calendars = calendars
  }

  @action
  async fetchCalendars() {
    const response = await axios.get('/v4/site/calendars')
    this.setCalendars(response.data.data)
  }

  get defaultCalendarId() {
    if (!this.calendars || this.calendars.length === 0) {
      return null
    }
    for (let i = 0; i < this.calendars.length; i++) {
      if (this.calendars[i].isDefault) {
        return this.calendars[i].id
      }
    }
    return this.calendars[0].id
  }
}

export const booking = BookingStore.ExtractVuexModule(BookingStore)

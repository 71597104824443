import Vue from 'vue'
import 'reflect-metadata'
import 'normalize.css/normalize.css'
import router from './router'
import vuetify from '@/plugins/vuetify'
import './plugins/fontawesome'
import VueHead from 'vue-head'
import store from './store'
import wait from './plugins/vuewait'
import i18n from './i18n'
import App from './App.vue'
import vueAxios from './plugins/vueaxios'
import EonButton from '@/components/ui/EonButton.vue'

import './registerServiceWorker'
import { appendSitePrefix } from '@/utils/routeUtils'

Vue.directive('site-href', (el: HTMLAnchorElement, binding) => {
  el.href = appendSitePrefix(binding.value)
})

Vue.use(VueHead)
Vue.config.productionTip = false

require('./common/default.sass')

/* eslint-disable no-new */
const vue = new Vue({
  store,
  router,
  i18n,
  vueAxios,
  vuetify,
  // @ts-ignore
  wait,
  render: (h) => h(App),
}).$mount('#app')

Vue.component('EonButton', EonButton)

export default vue













































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Moment from 'moment'
import { vxm } from '@/store'
import { Watch } from 'vue-property-decorator'
import { formatDateTimeToLocalDateTime, formatDateTimeToLocalTime } from '@/utils/dateUtils'

@Component({
  computed: {
    vxm() {
      return vxm
    },
  },
})
export default class NotificationsSection extends Vue {
  now: number = Moment.now()
  active = null
  loading = false
  readMoreDialog = false
  page = 1

  loadMoreNotifications() {
    this.loading = true
    this.page += 1
    vxm.notifications
      .loadMoreNotifications(this.page)
      .then(() => {
        this.readAllNotifications(3000)
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading more notifications')
        this.loading = false
      })
  }

  get notifications() {
    return vxm.notifications.notifications
  }

  get visible() {
    return vxm.overlay.section === 'notifications'
  }

  set visible(value) {
    if (!value) {
      vxm.overlay.close()
    }
  }

  truncateString(body) {
    const maxLength = 200
    let intro = ''
    const bodyWithLinks = this.formatContent(body)
    if (bodyWithLinks.length > maxLength) {
      const introParts = bodyWithLinks.split(' ')
      for (const introPart of introParts) {
        intro += introPart + ' '
        if (intro.length >= maxLength) {
          intro = intro.replace(/[^a-z0-9]+$/i, '') + '...'
          break
        }
      }
    } else {
      intro = bodyWithLinks
    }
    return intro
  }

  isToday(date) {
    return Moment(this.now).isSame(Moment.utc(date), 'day')
  }

  getTime(date) {
    return this.$t('Today') + ' ' + formatDateTimeToLocalTime(date)
  }

  getDate(date) {
    return formatDateTimeToLocalDateTime(date)
  }

  readMore(notification) {
    this.readMoreDialog = true
    this.active = notification
  }

  async readNotification(notification) {
    await vxm.notifications
      .readNotification(notification.id)
      .then(() => {
        notification.unread = false
        let count = vxm.notifications.unreadNotificationCounter
        vxm.notifications.setUnreadNotificationCounter(--count)
        if (--count === 0) {
          vxm.notifications.setHasNotifications(false)
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading notification')
      })
  }

  async unreadNotification(notification) {
    notification.notificationId = notification.id
    await vxm.notifications
      .unreadNotification(notification)
      .then(() => {
        notification.unread = true
        vxm.notifications.setHasNotifications(true)
        let count = vxm.notifications.unreadNotificationCounter
        vxm.notifications.setUnreadNotificationCounter(++count)
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading notification')
      })
  }

  async resolveNotification(notification) {
    notification.resolved = true
    try {
      const responseResolvedNotification = await vxm.notifications.resolveNotification(notification)
      if (notification.title.includes('IMPORTANT')) {
        notification.title = responseResolvedNotification.titleEnglish
      } else if (notification.title.includes('VIKTIGT')) {
        notification.title = responseResolvedNotification.titleSwedish
      } else if (notification.title.includes('VIKTIG')) {
        notification.title = responseResolvedNotification.titleNorwegian
      }
      notification.resolvedByUser = responseResolvedNotification.resolvedByUser
    } catch (err) {
      vxm.alert.onAxiosError(err, 'Error resolving notification')
    }
  }

  @Watch('visible')
  onVisibleChanged() {
    if (this.visible) {
      if (vxm.notifications.initialFetch === false) {
        vxm.notifications.fetchNotifications()
      }
      this.readAllNotifications(2500)
    }
  }

  readAllNotifications(timeout: number) {
    setTimeout(() => {
      Object.values(this.notifications).map(async(notification: any) => {
        notification.notificationId = notification.id
        if (notification.unread === true) {
          notification.loading = true
          await vxm.notifications.readNotification(notification.id)
          notification.unread = false
          notification.loading = false
        }
      })
      vxm.notifications.setHasNotifications(false)
      vxm.notifications.setUnreadNotificationCounter(0)
      this.loading = false
    }, timeout)
  }

  publishTimeClass(length: number, shouldResolve: boolean, resolved: boolean) {
    if (shouldResolve && !resolved) {
      return 'publish-time-2'
    }
    if (length > 140) {
      return 'publish-time-1'
    } else if (length > 85) {
      return 'publish-time-2'
    } else {
      return 'publish-time-1'
    }
  }

  formatContent(content) {
    // Replace plain text URLs with anchor tags
    return content.replace(
      /(https?:\/\/[^\s<>"']+|www\.[^\s<>"']+)/g,
      '<a href="$1" class="blue--text" target="_blank">$1</a>',
    )
  }

  formateNumericDate(datetime) {
    if (datetime === null) {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      const seconds = String(now.getSeconds()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    } else {
      return datetime.replace('T', ' ')
    }
  }
}

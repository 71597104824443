import { action, Module, mutation, VuexModule } from 'vuex-class-component'
import { axios } from '@/plugins/vueaxios'
import moment from 'moment'
import { AxiosError } from 'axios'

export type UserProfile = {
  id: number | null
  firstname: string
  lastname: string
  fullname: string
  tz: string
  email: string
  lang: string
  features: Array<string>
}

export type TokenType = {
  token: string
  payload: {
    sub: number
    aud: string
    siteName: string
    name: string
    iat: number
    exp: number
    iss: string
  }
}

@Module({ namespacedPath: 'user/' })
export class UserStore extends VuexModule {
  tokens: { [k: string]: TokenType } = {}
  currentSite: string | null = null

  site: any = {}
  chooseSite = false
  paymentExpiration = 0
  legacyFrameToken = ''
  profile: UserProfile = {
    id: null,
    firstname: '',
    lastname: '',
    fullname: '',
    tz: '',
    email: '',
    lang: '',
    features: [],
  }

  settings: Record<string, Record<string, string>> = {}

  @mutation
  setLegacyFrameToken(token: string) {
    this.legacyFrameToken = token
  }

  @mutation
  setTokens(tokens: string[]) {
    this.tokens = tokens
      .map((token) => {
        const payload = JSON.parse(atob(token.split('.')[1]))
        return [payload.aud, { token, payload }]
      })
      .reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
      }, {})
  }

  @mutation
  updateToken(token: string) {
    const payload = JSON.parse(atob(token.split('.')[1]))
    this.tokens = {
      ...this.tokens,
      [payload.aud]: {
        token,
        payload,
      },
    }
  }

  @mutation
  clearTokens() {
    this.tokens = {}
    this.legacyFrameToken = ''
  }

  @mutation
  cleanTokens() {
    this.tokens = Object.entries(this.tokens)
      .filter(([_key, { payload }]) => moment.unix(payload.exp).isAfter(moment()))
      .reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
      }, {})
  }

  @mutation
  private commitCurrentSite(siteId = null) {
    this.currentSite = siteId || null
  }

  @mutation
  setSiteId(siteId: number) {
    this.site.id = siteId
  }

  @mutation
  setSite(site: any) {
    this.site = site
  }

  @mutation
  setUserProfile(profile) {
    this.profile = profile
  }

  @mutation
  setPaymentExpiration(days: number) {
    this.paymentExpiration = days
  }

  @mutation
  setUserSettings({ settings = {}, userIdentifier }: { settings: {}; userIdentifier: string }) {
    this.settings[userIdentifier] = settings
  }

  @action
  async setCurrentSite(siteId) {
    this.commitCurrentSite(siteId)
    await this.refreshToken(siteId)
  }

  @action
  async refreshToken(siteId) {
    const token = this.tokens[siteId]
    if (!token) return
    const expiration = moment.unix(token.payload.exp)
    const diff = expiration.diff(moment(), 'day')
    if (diff <= 14) {
      try {
        const response = await axios.post('/v3/auth')
        this.updateToken(response.data.data.token)
      } catch (e) {
        if (Object.prototype.hasOwnProperty.call(e, 'response')) {
          const error: AxiosError = e
          if (error.response?.status === 401) {
            this.clearTokens()
          }
        }
      }
    }
  }

  @action()
  async fetchProfile() {
    return axios.get('/v3/user/profile').then(({ data }) => {
      let fullname = ''
      if (data && data.data && data.data.profile && data.data.profile.profile) {
        fullname = data.data.profile.profile.fullName || ''
      }
      this.setUserProfile({ ...data.data.profile, fullname: fullname })
    })
  }

  @action()
  async putUserSetting({ key, value }: { key: string; value: string }) {
    const userIdentifier = `${this.currentSite}_${this.profile.id}`
    const settings = { ...(this.settings[userIdentifier] || {}), [key]: value }
    this.setUserSettings({ userIdentifier, settings })
  }

  get hasToken(): boolean {
    return this.token !== null
  }

  get token() {
    if (this.currentSite && this.tokens.hasOwnProperty(this.currentSite)) {
      return this.tokens[this.currentSite].token
    }
    return null
  }

  get tokenPayload() {
    if (this.currentSite && this.tokens.hasOwnProperty(this.currentSite)) {
      return this.tokens[this.currentSite].payload
    }
    return null
  }

  get language() {
    return this.profile?.lang || ''
  }

  get userSettings(): Record<string, string> {
    if (this.currentSite !== null) {
      const userIdentifier = `${this.currentSite}_${this.profile.id}`
      const settings = this.settings[userIdentifier]
      return settings !== undefined ? settings : { logisticsSelectedLocation: null }
    }
    return {
      logisticsSelectedLocation: null,
    }
  }

  // ============================================================
  // Feature toggles
  // (Not sure how to pass arguments to getters here, could be improved probably)
  // ============================================================

  public get isSuperUser(): boolean {
    return this.profile?.features?.includes('IsSuperUser') || false
  }

  @action()
  public hasFeature(name: string): boolean {
    return this.profile?.features?.includes(name) || false
  }

  public get hasFeatureBookingCalendarView(): boolean {
    return this.profile?.features?.includes('BookingCalendarView') || false
  }

  public get hasFeatureUsePriceBonus(): boolean {
    return this.profile?.features?.includes('UsePriceBonus') || false
  }
}

export const user = UserStore.ExtractVuexModule(UserStore)








import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import { AlertType } from '@/types/ui/Alert'

@Component
export default class BaseAlert extends Vue {
  @Prop({ required: true }) identifier!: string
  @Prop({ default: false }) dismissible!: boolean
  @Prop({
    type: String,
    validator: (value) => ['info', 'error', 'success', 'warning'].includes(value),
  })
  type!: AlertType

  @Prop({ default: undefined }) icon!: string
  @Prop({ default: 5000 }) timeout!: number

  @Model('input') value!: boolean
  timeoutId = null

  get visible() {
    return this.value
  }

  set visible(value) {
    if (!value) {
      this.$emit('close', this.identifier)
    }
    this.$emit('input', value)
  }

  @Watch('visible', { immediate: true })
  onVisibilityChanged(visible) {
    if (visible && this.dismissible) {
      this.timeoutId = setTimeout(() => {
        this.visible = false
      }, this.timeout)
    } else {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId)
      }
    }
  }
}

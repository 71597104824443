import VueRouter from 'vue-router'
import store, { vxm } from '../store/index'

export default function (router: VueRouter) {
  router.beforeEach(async (to, from, next) => {
    vxm.queue.close()

    if (to.params.siteId !== undefined && vxm.user.currentSite !== to.params.siteId) {
      await vxm.user.setCurrentSite(to.params.siteId)
    }

    const reject = async (redirect = null) => {
      const prefix = to.params.siteId === undefined ? 'NoSite/' : ''
      await store.dispatch('logout')
      if (redirect === null) redirect = to.fullPath
      next({
        name: prefix + 'Login',
        query: {
          redirect: redirect || to.query.redirect,
        },
        params: prefix === '' ? { siteId: to.params.siteId } : {},
      })
    }

    // eslint-disable-next-line no-prototype-builtins
    if (to.path === '/logout' && to.query.hasOwnProperty('force')) {
      return reject('/')
    }
    // If route don't requireAuth or route is ChooseSite
    if (!to.matched.some((record) => record.meta.requireAuth) || to.name === 'ChooseSite') {
      return next()
    }
    if (Object.values(vxm.user.tokens).length === 0) {
      return reject()
    }
    if (!to.params.siteId) {
      next({
        name: 'ChooseSite',
        query: {
          redirect: to.fullPath,
        },
      })
      return
    }
    if (!Object.keys(vxm.user.tokens).some((siteId) => siteId === to.params.siteId)) return reject()
    next()
  })

  router.afterEach((_to, _from) => {
    vxm.overlay.close()
  })
}

import { DateTime } from 'luxon'

import { vxm } from '../store/index'

const getTimeZone = () => {
  return vxm?.user?.profile?.tz || 'UTC'
}

// The toLocaleString does not seem to work properly, shows "September 10, 2021" in my browser,
// while the correct for Norway should be "10. september 2021". So will go with the generic YYYY-MM-DD
// like before, for now. If need to change it later, it can be done in a unified way in here.

const DateFormat = 'yyyy-MM-dd'
const TimeFormat = 'HH:mm:ss'
const TimeFormatWithoutSeconds = 'HH:mm'

export function formatDateTimeString(text) {
  if (!text) {
    return ''
  }
  const timezone = getTimeZone()
  const dt = DateTime.fromISO(text.replace(' ', 'T'), { zone: 'UTC' })
  // return dt.setLocale('no').setZone(timezone).toLocaleString(DateTime.DATETIME_FULL)
  return dt.setLocale('no').setZone(timezone).toFormat(DateFormat + ' ' + TimeFormat)
}

export function formatDateTimeStringAsDate(text) {
  if (!text) {
    return ''
  }
  const timezone = getTimeZone()
  const dt = DateTime.fromISO(text.replace(' ', 'T'), { zone: 'UTC' })
  // return dt.setLocale('no').setZone(timezone).toLocaleString(DateTime.DATE_FULL)
  return dt.setLocale('no').setZone(timezone).toFormat(DateFormat)
}

export function formatDateTimeStringAsTime(text) {
  if (!text) {
    return ''
  }
  const timezone = getTimeZone()
  const dt = DateTime.fromISO(text.replace(' ', 'T'), { zone: 'UTC' })
  // return dt.setLocale('no').setZone(timezone).toLocaleString(DateTime.TIME_24_WITH_SECONDS)
  return dt.setLocale('no').setZone(timezone).toFormat(TimeFormat)
}

export function formatDateTimeStringAsTimeWithoutSeconds(text) {
  if (!text) {
    return ''
  }
  const timezone = getTimeZone()
  const dt = DateTime.fromISO(text.replace(' ', 'T'), { zone: 'UTC' })
  // return dt.setLocale('no').setZone(timezone).toLocaleString(DateTime.TIME_24_SIMPLE)
  return dt.setLocale('no').setZone(timezone).toFormat(TimeFormatWithoutSeconds)
}

export function formatDateTime(dt) {
  if (!dt) {
    return ''
  }
  const timezone = getTimeZone()
  return dt.setLocale('no').setZone(timezone).toLocaleString(DateTime.DATETIME_FULL)
}

export function dateTimeStringToUtcString(t: string): string {
  if (!t) {
    return ''
  }
  const timezone = getTimeZone()
  const dt = DateTime.fromISO(t.replace(' ', 'T'), { zone: timezone })
  return dt.setLocale('no').setZone('UTC').toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T')
}

export function formatDateTimeToLocalDateTime(date) {
  if (!date) {
    return ''
  }
  const utcDate = DateTime.fromISO(date, { zone: 'utc' })
  const localDate = utcDate.toLocal()
  const localTime = localDate.toFormat('HH:mm')
  return localDate.toFormat('EEE, MMM dd yyyy') + ' - ' + localTime
}

export function formatDateTimeToLocalTime(date) {
  if (!date) {
    return ''
  }
  const utcDate = DateTime.fromISO(date, { zone: 'utc' })
  const localDate = utcDate.toLocal()
  return localDate.toFormat('HH:mm')
}

import { action, Module, mutation, VuexModule } from 'vuex-class-component'
import { axios } from '@/plugins/vueaxios'
import i18n from '@/i18n'
import { DateTime } from 'luxon'
import { DumpMessage, GuiSocket, UpdateMessage } from '@/store/modules/queue/Socket'
import Person from '@/store/modules/queue/Person'
import Queue from '@/store/modules/queue/Queue'
import Storage from '@/store/modules/queue/Storage'
import Options from '@/store/modules/queue/Options'
import Status from '@/store/modules/queue/Status'

@Module({ namespacedPath: 'queue/' })
export class QueueStore extends VuexModule {
  private socket: GuiSocket = new GuiSocket()
  public people: Array<Person> = []
  public queues: Array<Queue> = []
  public now: DateTime = null
  public myQueueIdInternal = ''
  public expeditingPersonId = 0
  public options = new Options()
  public isVisible = false

  // Getters

  public get connected(): boolean {
    return this.socket?.connected && this.socket?.authenticated
  }

  public get myQueueId(): string {
    return this.myQueueIdInternal
  }

  public get peopleCompleted(): Array<Person> {
    const result = []
    for (let i = 0; i < this.people.length; i++) {
      if (this.people[i].status === Status.Done) {
        result.push(this.people[i])
      }
    }
    result.sort((a: Person, b: Person) => {
      const d1 = a.queue?.doneAt
      const d2 = b.queue?.doneAt
      if (!d1) return -1
      return d1 > d2 ? -1 : 1
    })
    return result
  }

  public get hasPeopleCompleted(): boolean {
    return this.peopleCompleted.length > 0
  }

  public get waitingCount(): number {
    let size = 0
    if (this.queues && this.queues.length > 0) {
      for (let i = 0; i < this.people.length; i++) {
        const p = this.people[i]
        if (p.status === Status.Wait && p.queueId === this.queues[0].id) {
          size++
        }
      }
    }
    return size
  }

  // Mutations

  @mutation
  public close() {
    this.isVisible = false
  }

  @mutation
  public toggle() {
    this.isVisible = !this.isVisible
  }

  @mutation
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public setMyQueueId(myQueueId: string) {
    this.myQueueIdInternal = myQueueId
    Storage.set('myQueueId', this.myQueueIdInternal)
  }

  @mutation
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public setExpeditePersonId(id: number) {
    this.expeditingPersonId = id
  }

  @mutation
  private initOptions(options: Options) {
    this.options = options
  }

  @mutation
  private updateNow() {
    this.now = DateTime.now().toUTC()
  }

  @mutation
  private initQueues() {
    this.queues = [
      new Queue({
        id: 'expedite',
        name: i18n.t('c:customer-queue:Reception') as string,
        myTarget: Storage.get('myTarget-expedite'),
      }),
      new Queue({
        id: 'garage',
        name: i18n.t('c:customer-queue:Garage') as string,
        myTarget: Storage.get('myTarget-garage'),
      }),
    ]
  }

  @mutation
  private setPeople(people: Array<Person>) {
    this.people = people
  }

  // Actions

  @action
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async onLogout() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }

  @action
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async onLogin() {
    axios
      .get('/v3/queue/options')
      .then((response) => {
        const data = response.data.data.options
        const options = new Options()
        options.callTimeMs = (data.callTime as number) * 1000
        options.expediteTargets = data.expediteTargets as Array<string>
        options.garageTargets = data.garageTargets as Array<string>
        options.enableGarageQueue = !!data.enableGarageQueue
        this.initOptions(options)
      })
      .catch((err) => {
        console.error('Error fetching queue-options:', err)
      })

    this.updateNow()
    setInterval(() => {
      this.updateNow()
    }, 5000)

    this.setMyQueueId(Storage.get('myQueueId') || 'expedite')
    this.initQueues()

    this.socket.connect(
      axios,
      () => this.onSocketAuthenticated(),
      (data) => this.onSocketUpdate(data),
      (data) => this.onSocketDump(data),
    )
  }

  @action
  private async onSocketAuthenticated(): Promise<unknown> {
    return axios.get('/v3/queue/refresh')
  }

  @action
  private async onSocketUpdate(data: UpdateMessage) {
    let person = null
    for (let i = 0; i < this.people.length; i++) {
      if (this.people[i].id === data.id) {
        person = this.people[i]
        break
      }
    }
    if (person) {
      person.update(data)
      if (person.id === this.expeditingPersonId && person.status !== Status.Expedite) {
        this.setExpeditePersonId(null)
      }
    } else {
      this.people.push(new Person(data))
    }
  }

  @action
  private async onSocketDump(data: DumpMessage) {
    // Remember who was expanded

    const expanded = {}
    for (let i = 0; i < this.people.length; i++) {
      if (this.people[i]._expanded) {
        expanded[this.people[i].id] = true
      }
    }

    // Make new array of people

    const people = []
    data.people.forEach((v) => {
      const person = new Person(v)
      person._expanded = !!expanded[person.id]
      people.push(person)
    })

    // Update state

    this.setPeople(people)
    this.setExpeditePersonId(data.expediting)
  }
}

export const queue = QueueStore.ExtractVuexModule(QueueStore)

import { DateTime } from 'luxon'
import Status from '@/store/modules/queue/Status.ts'
import { PersonInQueueMessage } from '@/store/modules/queue/Socket'
import { vxm } from '@/store'

function parseDate(value: string): DateTime {
  if (!value) {
    return null
  }
  const d = DateTime.fromISO(value.replace(' ', 'T'), { zone: 'UTC' })
  if (!d.isValid) {
    console.error('Invalid date: ' + value + ' - ' + d.invalidReason)
  }
  return d
}

export default class PersonQueueStatus {
  public queue: string
  public startAt: DateTime
  public expediteAt: DateTime
  public doneAt: DateTime
  public callAt: DateTime
  public target: string
  public status: number

  public constructor(data: PersonInQueueMessage) {
    if (!data) {
      data = new PersonInQueueMessage()
    }
    this.queue = data.queue
    this.startAt = parseDate(data.start_at)
    this.expediteAt = parseDate(data.expedite_at)
    this.doneAt = parseDate(data.done_at)
    this.callAt = null
    this.target = data.target
    this.status = data.status || Status.None
  }

  public update(data: PersonInQueueMessage): void {
    if (!data) {
      data = new PersonInQueueMessage()
    }
    this.startAt = parseDate(data.start_at)
    this.expediteAt = parseDate(data.expedite_at)
    this.doneAt = parseDate(data.done_at)
    this.callAt = parseDate(data.call_at)
    this.target = data.target
    this.status = data.status
  }

  public get waitTimeFormatted(): string {
    const diff = this.waitTimeMs
    if (diff === 0) {
      return ''
    }
    return Math.round(diff / 1000 / 60) + ' min'
  }

  public get waitTimeMs(): number {
    const start = this.startAt
    const end = this.expediteAt || vxm.queue.now
    const age = start && end ? end.toMillis() - start.toMillis() : 0
    return age > 0 ? age : 0
  }
}

import Vue from 'vue'

import { API_ROOT } from '@/config'

import AuthenticationHook from '@/api/AuthenticationHook'
import GlobalHandler from '@/api/GlobalHandler'
import VueAxios from '@asmadsen/vue-axios'
import VueAxiosInterface from '@asmadsen/vue-axios/types/VueAxiosInterface'

Vue.use(VueAxios)

const vueAxios = new VueAxios({
  authenticateDomains: [API_ROOT],
  baseURL: API_ROOT,
})

vueAxios.authentication.use(AuthenticationHook)
vueAxios.errorHandler.use(GlobalHandler)

export default vueAxios

export const axios: VueAxiosInterface = vueAxios.Axios

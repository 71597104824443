import { VuexModule, Module, mutation, action } from 'vuex-class-component'
import { axios } from '@/plugins/vueaxios'

@Module({ namespacedPath: 'easypos/' })
export class EasyposStore extends VuexModule {
  url: string | null = null

  get hasUrl() {
    return this.url !== null
  }

  @mutation
  updateSettings({ url }: { url: string }) {
    this.url = url
  }

  @mutation
  clearSettings() {
    this.url = null
  }

  @action
  async fetchSettings() {
    const data = await axios.get('/v3/integrations/easypos/settings').then((response) => response.data.data)

    this.updateSettings(data)
  }
}

export const easypos = EasyposStore.ExtractVuexModule(EasyposStore)




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getStyles } from '@/utils/styleFetcher'

@Component({
  components: {},
})
export default class NavigationDualButton extends Vue {
  @Prop({ default: false }) private listLabel: string
  @Prop({ default: false }) private newLabel: string
  @Prop({ default: false }) private listUrl: string
  @Prop({ default: false }) private newUrl: string

  private get style() {
    return getStyles()
  }

  // Orange border and background for "+"
  private get colorAccent() {
    return this.style?.colors?.secondary || '#f6a623'
  }
}
